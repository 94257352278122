(function (angular) {
    'use strict';

    angular.module('thehub.utils')
        .directive('thehubBlock', block);

    /** @ngInject */
    function block() {
        return {
            link: function (scope, element, attrs) {
                var eventNames = attrs.thehubBlock.trim().split(/ +/),
                    unbindQueue = [],
                    off;

                eventNames.forEach(function (eventName) {
                    element.on(eventName, genericBlocker);
                    unbindQueue.push(eventName);
                });

                off = scope.$on('$destroy', function () {
                    unbindQueue.forEach(function (eventName) {
                        element.off(eventName, genericBlocker);
                    });

                    unbindQueue = null;
                    off();
                });
            }
        };
    }

    function genericBlocker(evt) {
        evt.preventDefault();
    }

})(angular);
