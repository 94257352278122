(function (angular) {
    'use strict';

    angular.module('thehub.auth')
        /**
         * @ngdoc service
         * @name  authService
         * @module  thehub
         * @requires utilsDataService
         *
         * @description
         */
        .factory('authService', authService);

    /** @ngInject */
    function authService(utilsDataService, $http, BackendValuesService, $rootScope) {
        var auth = BackendValuesService.auth,
            httpConfig = {
                'headers': {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                'transformRequest': function(data) {
                    var isDataValid = angular.isObject(data) && String(data) !== '[object File]';

                    return isDataValid ? utilsDataService.urlEncodeObject(data) : data;
                }
            };

        return {
            /**
             * @ngdoc method
             * @name  authService#login
             *
             * @description
             * Attempts to log in and returns promise of the response.
             * 
             * @param  {Object} _data_      Simple object looking like so:
             *   {
             *       username: 'some_username',
             *       password: 'some_password'
             *   }
             * @return {Object}             Promise of the API response.
             */
            login: login,
            /**
             * @ngdoc method
             * @name authService#logout
             *
             * @description
             * Attempts to log out and returns promise of the response.
             * 
             * @return {Object} Promise of the http response.
             */
            logout: logout,
            /**
             * @ngdoc method
             * @name authService#getUser
             * 
             * @return {Object} 
             */
            getAuth: function () {
                return angular.copy(auth);
            }
        };

        function login(_data_) {
            var data = angular.copy(_data_);

            // Unfortunately, we support leading and trailing
            // spaces in password from front-end input.
            // We need to trim password and pass it to request.
            data.password = String(data.password || '').replace(/^[\s\xA0]+|[\s\xA0]+$/g, '');

            // The login API returns only success status, so we need to retain the useraneme from the login form.
            return $http.post('/api/admins/login', data, httpConfig).
                then(function () {
                    auth = {
                        username: data.username
                    };

                    $rootScope.$emit('thehub.auth:login', auth);
                });
        }

        function logout() {
            return $http.post('/api/admins/logout', httpConfig)
                .then(function () {
                    auth = false;

                    $rootScope.$emit('thehub.auth:logout', auth);
                });
        }
    }

})(angular);
