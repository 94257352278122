(function(angular) {
    'use strict';

    angular.module('inspinia')
        .run(runResponsive)
        .run(runState);

    /** @ngInject */
    function runResponsive($window, $document, $timeout) {
        $document.ready(function() {
            var _$window = angular.element($window);

            _$window.bind('load resize scroll', function() {
                if (!angular.element('body').hasClass('body-small')) {
                    fix_height();
                }
            });
            // Move right sidebar top after scroll
            _$window.scroll(function() {
                if (_$window.scrollTop() > 0 && !angular.element('body').hasClass('fixed-nav')) {
                    angular.element('#right-sidebar').addClass('sidebar-top');
                } else {
                    angular.element('#right-sidebar').removeClass('sidebar-top');
                }
            });

            $timeout(fix_height);

            // Minimalize menu when screen is less than 768px
            _$window.bind('load resize', function() {
                if ($document.width() < 769) {
                    angular.element('body').addClass('body-small');
                } else {
                    angular.element('body').removeClass('body-small');
                }
            });

            // Full height of sidebar
            function fix_height() {
                var heightWithoutNavbar = angular.element('body > #wrapper').height() - 61,
                    navbarHeigh = angular.element('nav.navbar-default').height(),
                    wrapperHeigh = angular.element('#page-wrapper').height();
                
                angular.element('.sidebard-panel').css('min-height', heightWithoutNavbar + 'px');

                if (navbarHeigh > wrapperHeigh) {
                    angular.element('#page-wrapper').css('min-height', navbarHeigh + 'px');
                }

                if (navbarHeigh < wrapperHeigh) {
                    angular.element('#page-wrapper').css('min-height', _$window.height() + 'px');
                }

                if (angular.element('body').hasClass('fixed-nav')) {
                    if (navbarHeigh > wrapperHeigh) {
                        angular.element('#page-wrapper').css('min-height', navbarHeigh - 60 + 'px');
                    } else {
                        angular.element('#page-wrapper').css('min-height', _$window.height() - 60 + 'px');
                    }
                }
            }
        });
    }

    /** @ngInject */
    function runState($rootScope, $state) {
        $rootScope.$state = $state;
    }
})(angular);
