(function (angular) {
    'use strict';

    angular.module('thehub.reports')
        .controller('ReportsDownloadsListController', ReportsDownloadsListController);

    function ReportsDownloadsListController ($http, downloadsResolved, $state, $stateParams) {
        var vm = this;

        vm.items = downloadsResolved.data.items;
        vm.pagination = downloadsResolved.data.pagination;
        vm.changePage = changePage;

        function changePage() {
            $state.go('admin.reports.downloads.search', {
                page: vm.pagination.current_page,
                reportId: $stateParams.reportId,
                domain: $stateParams.domain
            });
        }
    }
})(angular);
