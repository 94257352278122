(function(angular) {
    'use strict';

    angular.module('thehub.reports')
        .config(routerConfig);

    /** @ngInject */
    function routerConfig($stateProvider) {
        $stateProvider
            .state('admin.reports', {
                url: '/reports',
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('admin.reports.downloads', {
                url: '/downloads?reportId&domain&page',
                templateUrl: '/_partials/reports-downloads-search',
                controller: 'ReportsDownloadsSearchController as search',
                resolve: {
                    /** ngInject */
                    reportsResolved: function (reportsDataService) {
                        return reportsDataService.getList();
                    }
                }
            })
            .state('admin.reports.downloads.search', {
                url: '/search',
                templateUrl: '/_partials/reports-downloads-list',
                controller: 'ReportsDownloadsListController as list',
                resolve: {
                    /** ngInject */
                    downloadsResolved: function (reportsDataService, $stateParams) {
                        return reportsDataService.searchDownloads({
                            reportId: $stateParams.reportId,
                            domain: $stateParams.domain,
                            page: $stateParams.page || 1
                        });
                    }
                }
            });
    }
})(angular);
