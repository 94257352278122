(function (angular) {
    'use strict';

    angular.module('thehub.auth')
        .run(checkAuth);

    /** @ngInject */
    function checkAuth(authService, $state, $rootScope) {
        // Check if the current user is logged in before changing state.
        // If not - send them to the login state.
        $rootScope.$on('$stateChangeStart', function (event, toState) {
            if (!authService.getAuth() && 'login' !== toState.name) {
                event.preventDefault();
                $state.go('login');
            }
        });

        // thehub.auth:logout is fired every time the the user has been logged out.
        $rootScope.$on('thehub.auth:logout', function () {
            $state.go('login');
        });
    }
})(angular);
