(function(angular) {
    'use strict';

    angular.module('thehub.mailshots')
        .config(routerConfig);

    /** @ngInject */
    function routerConfig($stateProvider) {
        $stateProvider
            .state('admin.mailshots-courses', {
                url: '/mailshots-courses',
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('admin.mailshots-courses.search', {
                url: '/search',
                templateUrl: '/_partials/mailshots-courses-search',
                controller: 'CourseMailshotsController as search'
            })
            .state('admin.mailshots-courses.search.list-courses', {
                url: '/courses/:phrase/:page',
                templateUrl: '/_partials/mailshots-courses-list-courses',
                controller: 'CourseMailshotsListController as list',
                resolve: {
                    /** ngInject */
                    listResolved: function (CourseMailshotsDataService, $stateParams) {
                        return CourseMailshotsDataService.searchCourses({
                            phrase: $stateParams.phrase,
                            page: $stateParams.page || 1
                        });
                    },
                }
            })
            .state('admin.mailshots-courses.search.list-providers', {
                url: '/providers/:phrase/:page',
                templateUrl: '/_partials/mailshots-courses-list-providers',
                controller: 'CourseMailshotsListController as list',
                resolve: {
                    /** ngInject */
                    listResolved: function(CourseMailshotsDataService, $stateParams) {
                        return CourseMailshotsDataService.searchProviders({
                            phrase: $stateParams.phrase,
                            page: $stateParams.page || 1
                        });
                    },
                }
            });
    }
})(angular);
