(function (angular) {
    'use strict';

    angular.module('thehub.mailshots')
        .factory('CourseMailshotsDataService', CourseMailshotsDataService);

    function CourseMailshotsDataService($http) {
        return {
            searchCourses: searchCourses,
            searchProviders: searchProviders,
            changeCourseMailshotAction: changeCourseMailshotAction,
            changeProviderMailshotAction: changeProviderMailshotAction,
            addProviderAction: addProviderAction,
            addCourseAction: addCourseAction
        };

        function searchCourses(params) {
            return $http.get('/api/courses/search', { params: params });
        }

        function searchProviders(params) {
            return $http.get('/api/organisers/search', { params: params });
        }

        /**
         * On first promotion / exclusion we create action entry for a course
         * @param courseId
         * @param actionType
         */
        function addCourseAction(courseId, actionType) {
            var payload = {
                type: actionType
            };

            return $http.post('/api/courses/' + courseId + '/actions', payload);
        }

        /**
         * On first promotion / exclusion we create action entry for a course provider
         * @param providerId
         * @param actionType
         */
        function addProviderAction(providerId, actionType) {
            var payload = {
                type: actionType
            };

            return $http.post('/api/organisers/' + providerId + '/actions', payload);
        }

        /**
         * When action entry exist for course we update it instead of creating new
         * @param mailshotActionId
         * @param type
         */
        function changeCourseMailshotAction(mailshotActionId, type) {
            var payload = {
                type: type
            };

            return $http.post('/api/courses/actions/' + mailshotActionId, payload);
        }

        /**
         * When action entry exist for course provider we update it instead of creating new
         * @param mailshotActionId
         * @param type
         */
        function changeProviderMailshotAction(mailshotActionId, type) {
            var payload = {
                type: type
            };

            return $http.post('/api/organisers/actions/' + mailshotActionId, payload);
        }
    }
})(angular);
