(function (angular) {
    'use strict';

    angular.module('thehub.employers', [
        'thehub.core',
        'thehub.utils',
        'thehub.paymentLinks',
        'thehub.loginAs',
        'thehub.payments'
    ]);

})(angular);
