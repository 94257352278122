(function(angular) {
    'use strict';

    /**
     * @ngdoc controller
     * @name  CvWritingPurchasesFormController
     * @module  thehub.cvWriting
     *
     * @description
     * Uploads cv writing purchases data file to the server
     */
    angular.module('thehub.cvWriting')
        .controller('CvWritingPurchasesFormController', CvWritingPurchasesFormController);

    function CvWritingPurchasesFormController(PROVIDERS, Upload) {
        var vm = this;

        vm.data = {
            provider: undefined,
            file: undefined
        };
        vm.PROVIDERS = PROVIDERS;
        vm.submit = submit;

        function submit() {
            // Cleaning message so that after another click it's invisible
            vm.message = null;
            Upload.upload({
                url: '/api/candidates/cv-writing/purchases-upload',
                data: {file: vm.data.file, provider: vm.data.provider}
            }).then(function (response) {
                vm.message = {
                    type: 'success',
                    data: response.data
                };
            }, function (response) {
                vm.message = {
                    type: 'warning',
                    isValidationWarning: false
                };
                // TODO validation errors like this should be caught on each response
                if (400 === response.status) {
                    vm.message.data = response.data;
                    vm.message.isValidationWarning = true;
                }
            });
            vm.data.file = null;
        }
    }

})(angular);
