(function (angular) {
    'use strict';

    angular.module('thehub.reports')
        .controller('ReportsDownloadsSearchController', ReportsDownloadsSearchController);

    function ReportsDownloadsSearchController ($http, $stateParams, $state, reportsResolved) {
        var vm = this;

        vm.reports = reportsResolved.data.items;
        vm.report = findReport($stateParams.reportId, vm.reports) || vm.reports[0];
        vm.domain = $stateParams.domain;
        vm.submit = submit;

        function submit() {
            $state.go(
                'admin.reports.downloads.search',
                {
                    reportId: vm.report.id,
                    domain: vm.domain,
                    page: 1
                }
            );
        }
    }

    function findReport(id, reports) {
        if (!id) return;

        return reports.filter(function (item) {
            return item.id == id;
        })[0];
    }
})(angular);
