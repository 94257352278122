(function(angular) {
    'use strict';

    angular.module('thehub.affiliates')
        .config(routerConfig);

    /** @ngInject */
    function routerConfig($stateProvider) {
        $stateProvider
            .state('admin.affiliates', {
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('admin.affiliates.loginAsAffiliate', {
                url: '/affiliates/login-as',
                templateUrl: '/_partials/affiliates/login-as',
                controller: 'LoginAsUserFormController as loginAsAffiliate'
            });
    }
})(angular);
