(function(angular) {
    'use strict';

    angular.module('thehub.payments')
        .controller('PaymentsFormController', PaymentsFormController);

    class DefaultData {
        constructor() {
            this.customer = undefined;
            this.invoice_number = undefined;
            this.amount = undefined;
            this.email = undefined;
            this.last_order_date = undefined;
            this.total_amount = undefined;
        }
    }
    
    function PaymentsFormController($scope, paidCustomersResolved, paymentsDataService) {
        let vm = this;

        // Here is the data set we are expecting from the UI.
        vm.data = new DefaultData();

        vm.submit = submit;
        vm.customers = paidCustomersResolved.data.items;
        vm.showPaymentDetails = showPaymentDetails;

        function showPaymentDetails(id) {
            if (undefined == id) {
                alert('Please select a client!');
            } else {
                vm.data.last_payment_id = vm.data.customer.id;
                vm.data.invoice_number = vm.data.customer.invoiceNumber;
                vm.data.amount = vm.data.customer.amount;
                vm.data.email = vm.data.customer.email;
                vm.data.last_order_date = vm.data.customer.lastPaymentDate.slice(0, 10);
            }
        }

        // The controller is customer type agnostic, so the UI needs to say which type it is handling.
        function submit(customerType) {
            let payload = angular.copy(vm.data);

            payload.customer_type = customerType;
            // vm.data.customer can be either an object (if it's an existing customer)
            // or a string if it is a new customer.
            // If it's a string it is simply the customer reference (or name if you like).
            // If it's an object it looks like this:
            // {
            //     amount: 400
            //     email: "carlo@gulftalent.com"
            //     id: 271606
            //     invoiceNumber: "N002345"
            //     lastPaymentDate: "2021-06-16T03:59:49-0500"
            //     name: "Team Test"
            // }
            delete payload.customer;
            if ('object' === typeof vm.data.customer) {
                payload.payment_id = vm.data.customer.id;
                payload.amount = vm.data.total_amount;

                paymentsDataService.create(payload)
                    .then(result => {
                        vm.success = result.data.success;
                        vm.responseMessage = result.data.responseMessage
                    });
            } else {
                vm.success = false;
                vm.responseMessage = 'Client selection error!';
            }
        }
    }

})(angular);
