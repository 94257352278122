(function (angular) {
    'use strict';

    angular.module('inspinia')
        .directive('sideNavigation', function ($timeout) {
            return {
                restrict: 'A',
                link: function (scope, element, $window) {
                    var sidebar = element.parent();

                    // Call metsi to build when user signup
                    scope.$watch('authentication.user', function () {
                        $timeout(function () {
                            element.metisMenu();
                        });

                        // Enable initial fixed sidebar
                        if (angular.element('body').hasClass('fixed-sidebar')) {
                            sidebar.slimScroll({
                                height: '100%',
                                railOpacity: 0.9,
                            });
                        }
                    });
                }
            };
        });
})(angular);
