(function (angular) {
    'use strict';

    angular.module('thehub.paymentLinks')
        .factory('paymentLinksDataService', paymentLinksDataService);

    function paymentLinksDataService($http) {
        return {
            create: create
        };

        function create(data) {
            let payload = angular.copy(data),
                installmentProperties = [
                    'billing_cycle_type',
                    'billing_cycle_number',
                    'installments_number',
                    'instalment_amount'
                ];

            // If the payment has no installment plan, we don't pass the installment properties.
            if (!data.has_installments) {
                installmentProperties
                    .forEach(name => delete payload[name]);
            }

            // Drop all the time information including the timezone
            // Ensure the expiry is set to end of the day.
            payload.expires_at.setHours(23);
            payload.expires_at.setMinutes(59 - payload.expires_at.getTimezoneOffset());
            payload.expires_at.setSeconds(59);

            payload.expires_at = payload.expires_at.toISOString().replace(/Z$/, '');

            return $http.post('/api/payment-links', payload);
        }
    }

})(angular);
