(function (angular) {
    'use strict';

    angular.module('thehub.candidates', [
        'thehub.core',
        'thehub.utils',
        'thehub.cvWriting',
        'thehub.loginAs'
    ]);

})(angular);
