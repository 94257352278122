(function(angular) {
    'use strict';

    angular.module('thehub.courseOrganisers')
        .config(routerConfig);

    /** @ngInject */
    function routerConfig($stateProvider) {
        $stateProvider
            .state('admin.courseOrganisers', {
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('admin.courseOrganisers.loginAsCourseOrganiser', {
                url: '/course-organisers/login-as',
                templateUrl: '/_partials/course-organisers/login-as',
                controller: 'LoginAsUserFormController as loginAsCourseOrganiser'
            });
    }
})(angular);