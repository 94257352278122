(function (angular) {
    'use strict';

    angular.module('thehub.user')
        .controller('UserWidgetController', UserWidgetController);

    /** @ngInject */
    function UserWidgetController(authService) {
        var vm = this,
            auth = authService.getAuth();

        vm.username = auth && auth.username;
    }

})(angular);
