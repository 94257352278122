(function(angular) {
    'use strict';

    angular.module('thehub.employers')
        .config(routerConfig);

    /** @ngInject */
    function routerConfig($stateProvider) {
        $stateProvider
            .state('admin.employers', {
                url: '/employers',
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('admin.employers.paymentLink', {
                url: '/payment-link',
                templateUrl: '/_partials/employers-payment-link',
                controller: 'PaymentLinksFormController as paymentLink',
                resolve: {
                    /** @ngInject */
                    customersResolved: function (employersDataService) {
                        return employersDataService.getList();
                    }
                }
            })
            .state('admin.employers.loginAsEmployer', {
                url: '/employers/login-as',
                templateUrl: '/_partials/employers/login-as',
                controller: 'LoginAsUserFormController as loginAsEmployer'
            })
            .state('admin.employers.payment', {
                url: '/payments',
                templateUrl: '/_partials/employers-payment',
                controller: 'PaymentsFormController as payment',
                resolve: {
                    /** @ngInject */
                    paidCustomersResolved: function (employersDataService) {
                        return employersDataService.getPaidList();
                    }
                }
            });
    }
})(angular);
