(function (angular) {
    'use strict';

    angular.module('thehub.utils')
        /**
         * @ngdoc directive
         * @name thehubValidationClasses
         * @module thehub.utils
         * @description
         */
        .directive('thehubValidationClass', validationClass);

    function validationClass() {
        return {
            link: function (scope, element, attrs) {
                let formControls = [],
                    classMap = {
                        valid: 'has-success',
                        invalid: 'has-error'
                    },
                    unwatch,
                    off;

                if (!attrs.thehubValidationClassFor) return;

                formControls = scope.$eval(attrs.thehubValidationClassFor);
                if (!Array.isArray(formControls)) {
                    formControls = [formControls];
                }

                // Watch for changes in the validity of the observed form controls
                // and toggle validation classess accordingly.
                unwatch = scope.$watch(
                    () => {
                        let isValid;

                        isValid = formControls
                            .reduce(
                                (isValid, formControl) => isValid &&
                                    (!formControl.$dirty && !formControl.$touched || formControl.$valid),
                                true
                            );

                        return isValid;
                    },
                    (newValue, oldValue) => {
                        if (newValue !== oldValue) {
                            element.toggleClass(classMap.valid, newValue);
                            element.toggleClass(classMap.invalid, !newValue);
                        }
                    }
                );

                // We need to clean up after ourselves.
                off = scope.$on('$destroy', () => {
                    off();
                    unwatch();
                });
            }
        };
    }

})(angular);
