(function(angular) {
    'use strict';

    angular.module('thehub.comments')
        .config(routerConfig);

    /** @ngInject */
    function routerConfig($stateProvider) {
        $stateProvider
            .state('admin.comments', {
                url: '/comments',
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('admin.comments.list', {
                url: '/list?articleId',
                templateUrl: '/_partials/comments/list-comments',
                controller: 'CommentsController as gtComments',
                resolve: {
                    /** ngInject */
                    articlesResolved: function (commentsDataService) {
                        return commentsDataService.getArticleList();
                    }
                }
            })
            .state('admin.comments.edit', {
                url: '/edit/:commentId',
                templateUrl: '/_partials/comments/edit-comments',
                controller: 'EditCommentsController as gtEditComments',
                resolve: {
                    /** ngInject */
                    commentResolved: function (commentsDataService, $stateParams) {
                        return commentsDataService.getCommentDetails($stateParams.commentId);
                    }
                }
            });
    }
})(angular);