(function() {
    'use strict';

    angular.module('thehub')
        .config(routerConfig);

    /** @ngInject */
    function routerConfig($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('admin', {
                abstract: true,
                url: '/admin',
                templateUrl: '/_partials/content'
            })
            .state('admin.dashboard', {
                url: '/dashboard',
                templateUrl: '/_partials/dashboard',
                data: {
                    pageTitle: 'Example view'
                }
            });

        $urlRouterProvider.otherwise('/admin/dashboard');
    }
})();
