(function(angular) {
    'use strict';

    angular.module('thehub.candidates')
        .config(routerConfig);

    /** @ngInject */
    function routerConfig($stateProvider) {
        $stateProvider
            .state('admin.candidates', {
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('admin.candidates.cvWritingPurchases', {
                url: '/candidates/cv-writing/purchases',
                templateUrl: '/_partials/candidates/cv-writing-purchases',
                controller: 'CvWritingPurchasesFormController as cvWritingPurchases'
            })
            .state('admin.candidates.loginAsCandidate', {
                url: '/candidates/login-as',
                templateUrl: '/_partials/candidates/login-as',
                controller: 'LoginAsUserFormController as loginAsCandidate'
            });
    }
})(angular);
