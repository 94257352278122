(function(angular) {
    'use strict';

    angular.module('thehub.paymentLinks')
        .controller('PaymentLinksFormController', PaymentLinksFormController);

    class BillingCycle {
        constructor(name, number, type) {
            this.name = name;
            this.number = number;
            this.type = type;
        }
    }


    class DefaultData {
        constructor() {
            this.customer = undefined;
            this.product = undefined;
            this.total_amount = undefined;
            this.email = undefined;
            this.has_installments = false;
            this.is_installment = false;
            this.invoice_number = undefined;
            this.billing_cycle = undefined;
            this.installments_number = undefined;
            this.installment_amount = undefined;
            this.expires_at = new Date(new Date().setDate(new Date().getDate() + 50));
        }
    }
    
    function PaymentLinksFormController($scope, customersResolved, paymentLinksDataService) {
        let vm = this,
            unwatch,
            off;

        // Here is the data set we are expecting from the UI.
        vm.data = new DefaultData();

        vm.submit = submit;
        // Initialize the lists required by the UI:
        // - customers to choose from
        // - billing cycles
        // - installments numbers
        vm.customers = customersResolved.data.items;
        vm.billingCycles = [
            new BillingCycle('Monthly', 1, 'month'),
            new BillingCycle('Quarterly', 3, 'month'),
            new BillingCycle('Semi-Annually', 6, 'month'),
            new BillingCycle('Annually', 12, 'month')
        ];

        // The magic number below is getting rid of microtime from the microtimestamp,
        // in other words we are creating a datestamp:
        // 86400000 = 1000ms * 60s * 60m * 24h
        // The minimum expiration date is supposed to be greater than today,
        // and so we simply add 1 to the datestamp.
        vm.expiresAtMin = new Date(
            Math.floor(Date.now() / 86400000 + 1) * 86400000
        );

        vm.installmentsNumbers = [];
        for (let i = 1; i <= 36; i += 1) {
            vm.installmentsNumbers.push(i);
        }

        // Calculate the installment amount on the fly, whenever total amount or installments number changes.
        // We don't use $scope for anything else in the controller.
        unwatch = $scope.$watch(
            () => Math.floor(vm.data.total_amount / vm.data.installments_number),
            (newValue, oldValue) => {
                vm.data.installment_amount = isNaN(newValue) ? undefined : newValue;
            }
        );

        // We need to clean up after ourselves.
        off = $scope.$on('$destroy', () => {
            unwatch();
            off();
        });

        // The controller is customer type agnostic, so the UI needs to say which type it is handling.
        function submit(customerType) {
            let payload = angular.copy(vm.data);

            payload.customer_type = customerType;
            // vm.data.customer can be either an object (if it's an existing customer)
            // or a string if it is a new customer.
            // If it's a string it is simply the customer reference (or name if you like).
            // If it's an object it looks like this:
            //  {
            //      id: 124,
            //      name: 'Some Customer'
            //  }
            delete payload.customer;
            if ('object' === typeof vm.data.customer) {
                payload.customer_id = vm.data.customer.id;
                payload.customer_reference = vm.data.customer.name;
            } else {
                payload.customer_reference = vm.data.customer;
            }

            // Make the billing cycle digestible to the data service.
            delete payload.billing_cycle;
            if (vm.data.billing_cycle) {
                payload.billing_cycle_type = vm.data.billing_cycle.type;
                payload.billing_cycle_number = vm.data.billing_cycle.number;
            }

            paymentLinksDataService.create(payload)
                .then(result => {
                    vm.slug = result.data.items[0].slug;
                });
        }
    }

})(angular);
