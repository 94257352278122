(function (angular) {
    'use strict';

    angular.module('thehub.mailshots')
        .controller('CourseMailshotsListController', CourseMailshotsListController);

    function CourseMailshotsListController(
        listResolved,
        $state,
        $stateParams,
        CourseMailshotsDataService
    ) {
        var vm = this;

        vm.items = listResolved.data.items;
        vm.pagination = listResolved.data.pagination;
        vm.changePage = changePage;
        vm.addAction = addAction;
        vm.changeAction = changeAction;
        vm.toggleSelectAll = toggleSelectAll;
        vm.changeActionOnSelectedItems = changeActionOnSelectedItems;
        vm.selectAllCheckbox = false;
        vm.showStatusChangeError = false;

        function toggleSelectAll() {
            vm.items.forEach(function (item) {
                item.selected = vm.selectAllCheckbox;
            });
        }

        function changeActionOnSelectedItems(actionType){
            var selectedItems = vm.items.filter(function (item) {
                return item.selected;
            });

            if (0 === selectedItems.length) {
                vm.showStatusChangeError = true;

                return;
            }
            vm.showStatusChangeError = true;
            selectedItems.forEach(function (item) {
                if (!Array.isArray(item.mailshot_actions) || 0 === item.mailshot_actions.length) {
                    // error false when any valid item is found
                    vm.showStatusChangeError = false;
                    vm.addAction(actionType, item.id);
                } else if (item.mailshot_actions[0].type !== actionType) {
                    // error false when any valid item is found
                    vm.showStatusChangeError = false;
                    vm.changeAction(actionType, item.mailshot_actions[0].id, item.id);
                }
            });
        }

        function changePage() {
            $state.go($state.current.name, {
                page: vm.pagination.current_page,
                phrase: $stateParams.phrase
            });
        }

        function addAction(actionType, id) {
            var item = vm.items.find(function (e) {
                return e.id === id;
            });
            var promise;

            if ('admin.mailshots-courses.search.list-courses' === $state.current.name) {
                promise = CourseMailshotsDataService.addCourseAction(id, actionType);
            } else {
                promise = CourseMailshotsDataService.addProviderAction(id, actionType);
            }

            promise.then(function (response) {
                // after api hit refresh frontend model
                item.mailshot_actions = response.data.items;
            });
        }

        function changeAction(actionType, mailshotActionId, id) {
            var item = vm.items.find(function (e) {
                return e.id === id;
            });
            var promise;

            if ('admin.mailshots-courses.search.list-courses' === $state.current.name) {
                promise = CourseMailshotsDataService.changeCourseMailshotAction(mailshotActionId, actionType);
            } else {
                promise = CourseMailshotsDataService.changeProviderMailshotAction(mailshotActionId, actionType);
            }

            promise.then(function (response) {
                // after api hit refresh frontend model
                item.mailshot_actions = response.data.items;
            });
        }
    }
})(angular);
