(function (angular) {
    'use strict';

    //Directive used to set metisMenu and minimalize button
    angular.module('thehub.core', [
        'ngAnimate',
        'ngCookies',
        'ngTouch',
        'ngSanitize',
        'ngMessages',
        'ngAria',
        'ngResource',
        'ui.router',
        'ui.bootstrap',
        'angular-loading-bar'
    ]);
})(angular);
