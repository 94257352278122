(function (angular) {
    'use strict';

    angular.module('thehub.payments')
        .factory('paymentsDataService', paymentsDataService);

    function paymentsDataService($http) {
        return {
            create: create
        };

        function create(data) {
            let payload = angular.copy(data);

            return $http.post('/api/payments', payload);
        }
    }

})(angular);
