(function (angular) {
    'use strict';

    angular.module('thehub.auth')
        .config(routerConfig);

    /** @ngInject */
    function routerConfig($stateProvider) {
        $stateProvider
            .state('login', {
                url: '/login',
                templateUrl: '/_partials/login',
                controller: 'AuthController as auth'
            });
    }
})(angular);
