(function(angular) {
    'use strict';

    /**
     * @ngdoc controller
     * @name  LoginAsUserFormController
     * @module  thehub.loginAs
     *
     * @description
     * Tries to login admin user as the specified user.
     */
    angular.module('thehub.loginAs')
        .controller('LoginAsUserFormController', LoginAsUserFormController);

    function LoginAsUserFormController($http, $window, employersDataService) {
        var vm = this;

        vm.data = {
            email: undefined,
            parentCompanyId: undefined
        };
        vm.submit = submit;
        vm.getEmployers = getEmployers;

        function getEmployers(email) {
            employersDataService.getListByEmail(email)
                .then(
                    function (response) {
                        vm.companies = response.data.employers;
                    }, function () {
                        vm.message = {
                            type: 'error',
                            text: 'Oops, User was not found!'
                        };
                    }
                );
        }

        function submit(userType) {
            // Cleaning any previously set validation messages.
            vm.message = {};

            $http.post('/api/login-as/' + userType, vm.data)
                .then(function (response) {
                    vm.message = {
                        type: 'success',
                        text: 'Logging you in as a user in new browser tab!'
                    };
                    $window.open(response.data.redirectUrl, '_blank');
                }, function (response) {
                    vm.message = {
                        type: 'error',
                        text: response.data[0] && response.data[0].message ? response.data[0].message :
                            'Oops, something went wrong!'
                    };
                });
        }
    }
})(angular);
