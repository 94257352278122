(function(angular) {
    'use strict';

    angular.module('thehub.comments')
        .controller('EditCommentsController', EditCommentsController);

    function EditCommentsController($state, $stateParams, commentResolved, commentsDataService) {
        var vm = this;

        vm.comment = commentResolved.data.comment;
        vm.submit = submit;

        function submit() {
            commentsDataService.editCommentAction(vm.comment.id, vm.comment)
                .then(result => {
                    vm.success = result.data.success;
                    vm.responseMessage = 'Comment updated successfully.'

                    // Redirect to the search page
                    $state.go('admin.comments.list', { articleId: vm.comment.article_id });
                }, function (response) {
                    vm.success = false;
                    vm.responseMessage = response.data[0] && response.data[0].message ? response.data[0].message :
                        'Oops, something went wrong!';
                });
        }
    }
})(angular);
