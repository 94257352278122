(function (angular) {
    'use strict';

    angular.module('thehub.utils')
        .directive('thehubValidateSubmit', validateSubmit);

    /**
     * @ngdoc directive
     * @name thehubValidateSubmit
     * @module thehub.utils
     * @description
     * This directive attaches to the submit event on the form and checks upon form submission if the form is valid.
     * If it is then it does nothing.
     * If it isn't then it prevents form submission and sets all elements of the form as dirty.
     * This way the inline validation can kick in for the fields which where not originally modified by the user.
     */
    function validateSubmit() {
        return {
            priority: -1000,
            require: 'form',
            link: function (scope, element, attrs, formCtrl) {
                let off;

                element.on('submit', submit);

                // We need to clean up after ourselves.
                off = scope.$on('$destroy', () => {
                    element.off('submit', submit);
                    off();
                });

                function submit(evt) {
                    scope.$apply(() => {
                        if (formCtrl.$valid) {
                            return;
                        }

                        evt.stopImmediatePropagation();
                        evt.preventDefault();

                        Object.keys(formCtrl)
                            .filter(key => '$' !== key[0])
                            .forEach(key => {
                                formCtrl[key].$setDirty();
                            });
                    });
                }
            }
        };
    }

})(angular);
