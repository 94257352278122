(function (angular) {
    'use strict';

    angular.module('thehub.reports')
        .factory('reportsDataService', reportsDataService);

    function reportsDataService($http) {
        return {
            getList: getList,
            searchDownloads: searchDownloads
        };

        function getList() {
            return $http.get('/api/reports');
        }

        function searchDownloads(params) {
            return $http.get('/api/reports-downloads', { params: params });
        }
    }
})(angular);
