(function (angular) {
    'use strict';

    angular.module('thehub.utils')
        .filter('plainText', function() {
            return function(text) {
              return text ? String(text).replace(/<[^>]+>/gm, ' ') : ''; 
            };
          }
        );


})(angular);