(function(angular) {
    'use strict';

    angular.module('thehub.comments')
        .controller('CommentsController', CommentsController);

    function CommentsController($state, $stateParams, commentsDataService, articlesResolved) {
        var vm = this;

        vm.comments = [];
        vm.articles = articlesResolved.data.items;
        vm.article = findArticle($stateParams.articleId, vm.articles) || vm.articles[0];
        vm.submit = submit;
        vm.changeFilter = changeFilter;
        vm.editComment = editComment;
        vm.updateStatus = updateStatus;
        vm.filter = 'Pending';
        vm.displayComments = [];

        if ($stateParams.articleId) {
            submit();
        }

        function submit() {
            commentsDataService.getCommentsList(vm.article.id)
                .then(result => {
                    setComments(result.data.items);
                    filterComments(vm.filter);
                });
        }

        function updateStatus(status, commentId) {
            var comment = vm.comments.filter(function (comment) {
                return comment.commentId == commentId;
            })[0];

            comment.status = status;
            commentsDataService.editCommentAction(commentId, comment)
                .then(result => {
                    submit();
                });
        }

        function editComment(commentId) {
            $state.go('admin.comments.edit', {
                commentId: commentId
            });
        }

        function setComments(commentsObj) {
            var comments = [];
            var keys = Object.keys(commentsObj);

            keys.forEach(key => {
                var comment = commentsObj[key];
                comment.commentId = key;

                comments.push(comment);
            });
            comments.sort((a, b) => a.date > b.date ? -1 : 1);
            vm.comments = comments;
        }

        function changeFilter(filter) {
            vm.filter = filter;
            filterComments(filter);
        }

        function filterComments(filter) {
            vm.displayComments = vm.comments.filter(function (comment) {
                return comment.status == filter;
            })
        }

        function findArticle(id, articles) {
            if (!id) return;

            return articles.filter(function (item) {
                return item.id == id;
            })[0];
        }
    }

})(angular);