(function (angular) {
    'use strict';

    angular.module('thehub.utils')
        /**
         * @ngdoc service
         * @name utilsDataService
         * @module thehub
         * @description
         * The "constant" type service exposing utility methods for data manipulation.
         */
        .constant('utilsDataService', getService());

    function getService() {
        var service = {
            /**
             * @ngdoc method
             * @name  utilsDataService#urlEncodeObject
             *
             * @description
             * Serializes given object to x-www-form-urlencoded format.
             *
             * @param {Object} object       Object to be serialized.
             * @param {String} rootKey      Optional. The key under which the object should be serialized.
             * 
             * @return {String} Serialized representation of the given object.
             **/
            urlEncodeObject: urlEncodeObject
        };

        return service;

        function urlEncodeObject(object, rootKey) {
            return Object.keys(object)
                .reduce(flattenObject, [])
                .join('&');

            function flattenObject(result, key) {
                var value = object[key];

                if (value instanceof Object) {
                    result.push(
                        urlEncodeObject(value, serializeKey(key, rootKey))
                    );
                } else if (undefined !== value && null !== value) {
                    result.push(
                        encodeURIComponent(serializeKey(key, rootKey)) + '=' + encodeURIComponent(value)
                    );
                }

                return result;
            }
        }

        function serializeKey(key, rootKey) {
            return rootKey ? rootKey + '[' + key + ']' : key;
        }
    }

})(angular);
