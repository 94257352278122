(function (angular) {
    'use strict';

    angular.module('thehub.auth')
        .controller('AuthController', function (authService, $state) {
            var vm = this;

            vm.form = {};

            vm.login = function () {
                authService.login(vm.form)
                    .then(function () {
                        $state.go('admin.dashboard');
                    });
            };
        });
})(angular);
