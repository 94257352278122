(function(angular) {
    'use strict';

    angular.module('thehub.mailshots')
        .controller('CourseMailshotsController', CourseMailshotsController);

    function CourseMailshotsController($state) {
        var vm = this;

        vm.phrase = $state.params.phrase;
        if ('admin.mailshots-courses.search.list-providers' === $state.current.name) {
            vm.context = 'providers';
        } else {
            vm.context = 'courses';
        }

        vm.changeInputPlaceholder = function () {
            vm.inputPlaceholder = 'courses' === vm.context ?
                'Enter course title or provider name' :
                'Enter provider name';
        }

        vm.submit = function submit() {
            if ('courses' === vm.context) {
                $state.go(
                    'admin.mailshots-courses.search.list-courses',
                    {
                        phrase: vm.phrase,
                        page: 1
                    }
                );
            } else {
                $state.go(
                    'admin.mailshots-courses.search.list-providers',
                    {
                        phrase: vm.phrase,
                        page: 1
                    }
                );
            }
        };
    }

})(angular);
