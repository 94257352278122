(function (angular) {
    'use strict';

    angular.module('thehub.auth')
        .directive('thehubLogout', logout);

    /** @ngInject */
    function logout(authService) {
        return {
            link: function (scope, element) {
                let off;

                element.on('click', clickHandler);

                off = scope.$on('$destroy', function () {
                    element.off('click', clickHandler);
                    off();
                });
            }
        };

        function clickHandler(evt) {
            evt.preventDefault();
            authService.logout();
        }
    }
})(angular);
