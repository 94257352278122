(function(angular) {
  'use strict';

    angular.module('thehub', [
        'thehub.core',
        'thehub.auth',
        'thehub.reports',
        'thehub.user',
        'thehub.employers',
        'thehub.comments',
        'thehub.candidates',
        'thehub.affiliates',
        'thehub.courseOrganisers',
        'thehub.mailshots',
        'inspinia'
    ]);

})(angular);
