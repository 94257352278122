(function(angular) {
    'use strict';

    angular.module('thehub.comments')
        .factory('commentsDataService', commentsDataService);

    function commentsDataService($http) {
        return {
            getArticleList: getArticleList,
            getCommentsList: getCommentsList,
            getCommentDetails: getCommentDetails,
            editCommentAction: editCommentAction
        };

        function getArticleList() {
            return $http.get('/api/articles/list');
        }

        function getCommentsList(articleId) {
            return $http.get('/api/articles/' + articleId + '/comments/list');
        }

        function getCommentDetails(commentId) {
            return $http.get('/api/articles/comments/' + commentId);
        }

        /**
         * Action when editing the comment
         * @param commentId
         * @param payload
         */
        function editCommentAction(commentId, payload) {
            return $http.post('/api/articles/comments/' + commentId, { payload : JSON.stringify(payload) });
        }
    }
})(angular);