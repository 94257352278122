(function(angular) {
    'use strict';

    angular.module('thehub.employers')
        .factory('employersDataService', employersDataService);

    function employersDataService($http) {
        return {
            getList: getList,
            getPaidList: getPaidList,
            getListByEmail: getListByEmail
        };

        function getList() {
            return $http.get('/api/employers');
        }

        function getPaidList() {
            return $http.get('/api/paid-employers');
        }

        function getListByEmail(email) {
            return $http.get('/api/employee/employers?email=' + email);
        }
    }
})(angular);
